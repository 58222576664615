@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Exo:wght@300;400;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
/* Apply styles to all */
@layer base {
  body {
    @apply font-[Raleway];
  } 
  p {
    @apply font-[Poppins];
    /* @apply leading-snug; */
    @apply font-light;
  }
}
/* p { */
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Exo', sans-serif; */
/* } */

html, body {
  overflow-x: hidden;
}